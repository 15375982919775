import { render, staticRenderFns } from "./SchoolTrendsLine.vue?vue&type=template&id=bf58a058&scoped=true&"
import script from "./SchoolTrendsLine.vue?vue&type=script&lang=js&"
export * from "./SchoolTrendsLine.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bf58a058",
  null
  
)

export default component.exports