<template>
  <div class="echart" id="echart-SchoolTrendsLine" :style="{ float: 'left', width: '100%', height: '100%' }"></div>
</template>

<script>
import echarts from 'echarts';
export default {
  name: 'SchoolTrendsLine',
  data(){
    return {
      color: ['#8B5CFF'],
      dataList: [{
        name: '08:10',
        value: '0.15'
      }, {
        name: '08:20',
        value: '0.18'
      }, {
        name: '08:30',
        value: '0.14'
      }, {
        name: '08:40',
        value: '0.18'
      }, {
        name: '08:50',
        value: '0.17'
      }, {
        name: '09:00',
        value: '0.12'
      }, {
        name: '09:10',
        value: '0.15'
      }, {
        name: '09:20',
        value: '0.14'
      }],
      yAxisData1: [100, 138, 350, 173, 180, 150, 180, 230]
    }
  },
  methods: {
    initChart() {
      let getchart = echarts.init(document.getElementById('echart-SchoolTrendsLine'));
      var SchoolTrendsLineOption = {
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['学时'],
          show:false,
          textStyle: {
            color: "#fff"
          }
        },
        title: {

          left: 26,
          top: 26,
          textStyle: {
            color: '#FFFFFF',
            fontSize: 10,
            fontWeight: 50000,
            fontFamily: 'PingFang SC'
          }
        },
        grid: {
          left: '2%',
          right: '2%',
          bottom: '5%',
          top: '8%',
          containLabel: true
        },
        dataZoom: [{
          type: 'inside',
          start: 0,
          end: this.dataList.length > 15 ? 35 : 100
        }],
        xAxis: {
          axisLine: {
            lineStyle: {
              color: '#397cbc'
            }
          },
          splitLine: {
            show: false,
          },
          axisTick: {
            show: false
          },
          //轴线上的字
          axisLabel: {
            show: true,
            textStyle: {
              color: '#cecece',
              fontSize: '10'
            },
            rotate: '-45',
          },
          data: ['08:10', '08:20', '08:30', '08:40', '08:50', '09:00', '09:10', '09:20']
        },
        yAxis: [{
          type: 'value',
          splitNumber: 4,
          axisTick: {
            show: false
          },
          //轴线上的字
          axisLabel: {
            textStyle: {
              fontSize: '10',
              color: '#cecece'
            },
          },
          axisLine: {
            lineStyle: {
              color: '#397cbc'
            }
          },
          //网格线
          splitLine: {
            lineStyle: {
              color: '#11366e'
            }
          }
        }],
        series: [{
          name: '学时',
          type: 'line',
          smooth: true, //是否平滑曲线显示
          showSymbol: false,
          itemStyle: {
            color: '#3eb5dd',
            borderColor: '#f1f1f1',
            borderWidth: 1
          },
          lineStyle: {
            normal: {
              width: 4,
              color: {
                type: 'linear',
                colorStops: [{
                  offset: 0,
                  color: '#57b3e5' // 0% 处的颜色
                },
                  {
                    offset: 1,
                    color: '#31a9ea' // 100% 处的颜色
                  }
                ],
                globalCoord: false // 缺省为 false
              },
              shadowColor: 'rgba(249,165,137, 0.5)',
              shadowBlur: 30,
              shadowOffsetY: 5
            }
          },
          areaStyle: {
            //区域填充样式
            normal: {
              color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [{
                    offset: 0,
                    color: "rgba(58,182,224, 0.6)"
                  }, {
                    offset: 0.6,
                    color: "rgba(58,182,224, 0.2)"
                  },
                    {
                      offset: 0.8,
                      color: "rgba(58,182,224, 0.01)"
                    }
                  ],
                  false
              ),
              shadowColor: "rgba(58,182,224, 0.1)",
              shadowBlur: 6
            }
          },
          data: this.dataList
        }]
      };

      getchart.setOption(SchoolTrendsLineOption);
      //随着屏幕大小调节图表
      window.addEventListener('resize', () => {
        getchart.resize();
      });
    },

    hexToRgba(hex, opacity){
      let rgbaColor = '';
      let reg = /^#[\da-f]{6}$/i;
      if (reg.test(hex)) {
        rgbaColor = `rgba(${parseInt('0x' + hex.slice(1, 3))},${parseInt('0x' + hex.slice(3, 5))},${parseInt(
            '0x' + hex.slice(5, 7)
        )},${opacity})`;
      }
      return rgbaColor;
    }
  },
  mounted() {
    this.initChart();
  },
};
</script>

<style scoped></style>
