<template>
  <Table  :row-class-name="rowClassName" :columns="columns1" :data="data1"></Table>
</template>

<script>
export default {
  name: "BITable",
  data(){
    return {
      columns1: [
        {
          title: '班级名称',
          key: 'name',
          align: 'center',
        },
        {
          title: '学员数',
          key: 'xys',
          align: 'center',
        },
        {
          title: '课程',
          key: 'kc',
          align: 'center',
        },
        {
          title: '起止时间',
          key: 'qzsj',
          align: 'center',
        },
        {
          title: '代课教师',
          key: 'dkjs',
          align: 'center',
        },
      ],

      data1: [
        {
          name: '班级(1)',
          xys: 18,
          kc: '初级护理课',
          qzsj: '8:00-10:30',
          dkjs:'闫某人'
        },
        {
          name: '班级(2)',
          xys: 18,
          kc: '初级护理课',
          qzsj: '8:00-10:30',
          dkjs:'闫某人'
        },
        {
          name: '班级(3)',
          xys: 18,
          kc: '初级护理课',
          qzsj: '8:00-10:30',
          dkjs:'闫某人'
        },
        {
          name: '班级(4)',
          xys: 18,
          kc: '初级护理课',
          qzsj: '8:00-10:30',
          dkjs:'闫某人'
        },
        {
          name: '班级(5)',
          xys: 18,
          kc: '初级护理课',
          qzsj: '8:00-10:30',
          dkjs:'闫某人'
        },
        {
          name: '班级(6)',
          xys: 18,
          kc: '初级护理课',
          qzsj: '8:00-10:30',
          dkjs:'闫某人'
        },

      ]
    }
  },
  methods:{
    rowClassName (row, index) {
      if (index >= 0) {
        return 'demo-table-info-row';
      }
      return '';
    }
  }
}
</script>

<style>
.ivu-table{
  background: none;
}
.ivu-table:before{
  background: none;
}
.ivu-table th{
  background-color: rgba(255,255,255,0);
  color: white;
  border: none;
}

.ivu-table .demo-table-info-row td{
  background: none;
  color: white;
  border:none;
}




</style>